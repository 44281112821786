import Loadable from 'react-loadable';
import { asTemplate } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { QUESTION } from '@apps/firsthomecoach/src/helpers/navigation';
import { Loader } from '@apps/firsthomecoach/src/components/with-loader';
import { DepositBuilderAccountsQuestion } from '@apps/firsthomecoach/src/pages/question/enhancement/deposit-builder-accounts';
import { DepositBuilderAccountNameQuestion } from '@apps/firsthomecoach/src/pages/question/enhancement/deposit-builder-account-name';
import { SECTION_CHECKPOINT_QUESTION_SLUG } from '@apps/firsthomecoach/src/helpers/chekpoint-page/constants';

export const Question = Loadable({
  loader: () =>
    import(/* webpackChunkName: "question"*/ '@apps/firsthomecoach/src/pages/question').then(module => module.Question),
  loading: Loader
});

const PropertyQuestion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "propertyQuestion" */ '@apps/firsthomecoach/src/pages/question/enhancement/property-portfolio-property-question'
    ).then(module => module.PropertyQuestion),
  loading: Loader
});

const PropertyMortgageQuestion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "propertyMortgageQuestion" */ '@apps/firsthomecoach/src/pages/question/enhancement/property-portfolio-property-mortgage-question'
    ).then(module => module.PropertyMortgageQuestion),
  loading: Loader
});

const SectionCheckpointQuestion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "section-checkpoint" */ '@apps/firsthomecoach/src/pages/question/section-checkpoint'
    ).then(module => module.SectionCheckpointQuestion),
  loading: Loader
});

const AffordabilityLoading = Loadable({
  loader: () =>
    import(/* webpackChunkName: "affordability-loading"*/ '@apps/firsthomecoach/src/pages/affordability-loading').then(
      module => module.AffordabilityLoading
    ),
  loading: Loader
});

export const questions = [
  {
    to: '/affordability-loading',
    component: asTemplate(QUESTION)(AffordabilityLoading)
  },
  {
    to: `/checklist/:activity/${SECTION_CHECKPOINT_QUESTION_SLUG}`,
    component: asTemplate(QUESTION)(SectionCheckpointQuestion)
  },
  {
    to: '/checklist/property-portfolio/:slug',
    component: asTemplate(QUESTION)(PropertyQuestion)
  },
  {
    to: '/checklist/addMortgage/:slug',
    component: asTemplate(QUESTION)(PropertyMortgageQuestion)
  },
  {
    to: '/checklist/deposit-builder/accounts',
    component: asTemplate(QUESTION)(DepositBuilderAccountsQuestion)
  },
  {
    to: '/checklist/deposit-builder/name-your-account',
    component: asTemplate(QUESTION)(DepositBuilderAccountNameQuestion)
  },
  {
    to: '/(checklist|account|market-place)/:activity/:slug/:identifier?',
    component: asTemplate(QUESTION)(Question)
  }
];
