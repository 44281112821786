import { TLoader } from '@apps/firsthomecoach/src/components/template';
import Loadable from 'react-loadable';

const DigitalCoach = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-digital-coach"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/digital-coach'
    ).then(module => module.DigitalCoach),
  loading: TLoader
});

const DashboardAdverts = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-adverts"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/adverts').then(
      module => module.DashboardAdverts
    ),
  loading: TLoader
});

const MABFinanceSummary = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-mab-finance-summary"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/mab-portfolio/finance-summary'
    ).then(module => module.MABFinanceSummary),
  loading: TLoader
});

const MABPortfolio = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-mab-portfolio"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/mab-portfolio/portfolio'
    ).then(module => module.MABPortfolio),
  loading: TLoader
});

const MyPlan = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-my-plan"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/plan').then(
      module => module.MyPlan
    ),
  loading: TLoader
});

const MarketPlaceWidget = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-marketplace"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/marketplace'
    ).then(module => module.MarketPlaceWidget),
  loading: TLoader
});

const MortgageTool = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-mortgage-tool"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/mortgage-tool'
    ).then(module => module.MortgageTool),
  loading: TLoader
});

const ContentRepeater = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-content-repeater"*/ '@apps/firsthomecoach/src/widgets/repeater/content-repeater.jsx'
    ).then(module => module.DashboardContentRepeater),
  loading: TLoader
});

const IntroducerBanner = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "introducer-banner"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/introducer-banner'
    ).then(module => module.IntroducerBanner),
  loading: TLoader
});

export const dashboardWidgets = {
  digitalCoach: DigitalCoach,
  adverts: DashboardAdverts,
  repeater: ContentRepeater,
  myPlan: MyPlan,
  marketplace: MarketPlaceWidget,
  MABFinanceSummary: MABFinanceSummary,
  MABPortfolio: MABPortfolio,
  mortgageTool: MortgageTool,
  introducerBanner: IntroducerBanner
};
