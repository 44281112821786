import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import '@apps/firsthomecoach/src/styles/index.scss';
import { ErrorBoundary } from '@apps/firsthomecoach/src/components/error-boundary';
import { ThemeProvider } from '@apps/firsthomecoach/src/components/theme';
import { SlotsProvider } from '@apps/firsthomecoach/src/components/slots';
import { ScrollPositionController } from '@apps/firsthomecoach/src/components/scroll-position';
import { EmbeddedProvider } from '@apps/firsthomecoach/src/components/layout/with-embedded-context';
import { TrackingService } from '@apps/firsthomecoach/src/services/tracking-service';
import { TRACKING_STATUSES } from '@apps/firsthomecoach/src/helpers/constants';

import { App } from './App';
import { store } from './store';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'unfetch/polyfill';
// eslint-disable-next-line import/no-unassigned-import
import 'beautiful-react-redux/patch';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <ThemeProvider>
          <ScrollPositionController>
            <SlotsProvider>
              <EmbeddedProvider>
                <App />
              </EmbeddedProvider>
            </SlotsProvider>
          </ScrollPositionController>
        </ThemeProvider>
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
);

TrackingService.setTrackerTimestamp(TRACKING_STATUSES.APP_INIT);
